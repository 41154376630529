import React, { memo } from 'react';
import { graphql } from 'gatsby';
import Lazy, { LazyLoadProps } from 'react-lazyload';
import Media from 'react-media';
import { PingboardPerson } from '../../../gatsby-framework/pingboard/PingboardPerson.interface';
import Layout from '../layout/layout';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { Col, Container, Row } from 'react-bootstrap';
import { Div, H2, Img, Span } from '../common/Elements';
import { ImageTextBlade } from '../common/refresh-blades/ImageTextBlade';
import { HeroBlade } from '../common/refresh-blades/HeroBlade';
import { GlobalStrings } from '../../common/fragments/MRKConfig';
import { useStaticStrings } from '../../common/hooks/useStaticStrings';

export const pageQuery = graphql`
  query($locale: String!) {
    globalStrings: mrkConfigStaticStrings(
      contentfulId: { eq: "1uP0ac1EPF1qsviUi4I9Vz" }
      locale: { eq: $locale }
    ) {
      ...GlobalStringsFragment
    }

    hero: bladeHeroAnimation(contentfulId: { eq: "3b6jfqqJx5Fjcbo7ndH7aE" }) {
      ...bladeHeroAnimationFragment
    }

    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
  }
`;

interface Person {
  id: string;
  first_name: string;
  img_path: string;
  job_title?: string;
}

// We don't want the lazy images to load on for SSR because otherwise people
// without JS will end up two spots for images (one for noscript and one lazy)
const LazyOnMount: React.FC<LazyLoadProps> = props => {
  const [didMount, setDidMount] = React.useState(false);
  React.useEffect(() => setDidMount(true), []);
  return didMount ? <Lazy {...props}>{props.children}</Lazy> : null;
};

const PersonImage: React.FC<{ notDesktop: boolean; person: Person }> = ({ notDesktop, person }) => (
  <>
    <noscript>
      <Img
        display="block"
        width="100%"
        lineHeight="solid"
        marginBottom={2}
        title={person.first_name}
        alt={person.first_name}
        src={person.img_path}
      />
    </noscript>
    <LazyOnMount height={158 + 16} offset={500}>
      <Img
        display="block"
        width="100%"
        lineHeight="solid"
        style={{ height: notDesktop ? 'auto' : '158px' }}
        marginBottom={2}
        title={person.first_name}
        alt={person.first_name}
        src={person.img_path}
      />
    </LazyOnMount>
  </>
);

const boardMembers: Person[] = [
  {
    id: '1',
    first_name: 'Mark Anderson',
    img_path: '/img/people/board/mark.jpg',
    job_title: 'Director',
  },
  {
    id: '2',
    first_name: 'Maria Eitel',
    img_path: '/img/people/board/maria.jpg',
    job_title: 'Director',
  },
  {
    id: '3',
    first_name: 'Stan Meresman',
    img_path: '/img/people/board/stan.jpg',
    job_title: 'Director',
  },
  {
    id: '4',
    first_name: 'Katrin Suder',
    img_path: '/img/people/board/katrin.jpg',
    job_title: 'Director',
  },
  {
    id: '5',
    first_name: 'Carl Ledbetter',
    img_path: '/img/people/board/carl.jpg',
    job_title: 'Director',
  },
  {
    id: '6',
    first_name: 'Scott Sandell',
    img_path: '/img/people/board/scott.jpg',
    job_title: 'Director',
  },
];

const PeopleList: React.FC<{ people: Person[]; notDesktop: boolean }> = props => {
  return (
    <Container>
      <Row>
        {props.people.map(person => (
          <Col key={person.id} lg={2} md={4} xs={6}>
            <Div paddingBottom={6}>
              <PersonImage person={person} notDesktop={props.notDesktop} />
              <Div fontSize={3} fontWeight={6} lineHeight="solid" marginBottom={1}>
                {person.first_name}
              </Div>
              {person.job_title && (
                <Div fontSize={2} lineHeight="solid">
                  <Span lineHeight="copy">{person.job_title}</Span>
                </Div>
              )}
            </Div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

type PeoplePageProps = { data: any } & GatsbyTemplateProps<
  AppContextProps & { people: PingboardPerson[] }
>;
export const PeoplePage: React.FC<PeoplePageProps> = props => {
  const globalStrings = useStaticStrings();

  return (
    <Layout
      redwoodNavData={props.data.redwoodNavData}
      utilityNavData={props.data.utilityNavData}
      redwoodRightNavData={props.data.redwoodRightNavData}
      pageContext={props.pageContext}
      topNavData={props.data.navData}
      footerData={props.data.footerData}
    >
      <div className="people-page">
        <HeroBlade
          blade={props.data.hero}
          page={{
            contentTypeId: 'page',
            contentfulId: '',
            relativePath: '',
            pageName: '',
            metaTitle: '',
            metaDescription: '',
          }}
        />
        <Div marginTop={9}>
          <Media
            queries={{
              large: '(min-width: 1200px)',
            }}
            defaultMatches={{ large: true }}
          >
            {matches => (
              <>
                <PeopleList
                  people={props.pageContext.people.map(person => ({
                    id: person.id,
                    first_name: person.first_name,
                    img_path: (person as any).avatar?.publicURL,
                  }))}
                  notDesktop={!matches.large}
                />
                <Container>
                  <Row>
                    <H2 marginBottom={6} fontSize={5} marginLeft={2}>
                      {globalStrings.json_values.Our_Board}
                    </H2>
                  </Row>
                </Container>
                <PeopleList people={boardMembers} notDesktop={!matches.large} />
              </>
            )}
          </Media>
        </Div>
      </div>
    </Layout>
  );
};

export default PeoplePage;
